/* istanbul ignore file */
import $ from 'jquery';
import dt from 'datatables.net';

/**
 * JS integration for DataTables. View documentation at https://datatables.net/manual/data/
 * Includes additional JS to style the datatable. Further notes inline.
 * JS version of app/assets/javascripts/globals/datatables.coffee.erb
 * @param {string} url - Url that will provide data for the datatable. Typically uses the request url
 * @param {hash} images - A hash that contains the asset paths for all images used on the page
 * @param {string} placeholder - Placeholder string for the search bar
 * @param {function} changeFunc  - A callback that is called when there are changes to the datatable
 */

const datatable = (url, order = [0,'desc'], images, placeholder, prescribers, changeFunc = () => {}) => {
  $(function() {
    $('[data-table]').each((column) => {
      let columnIdx = $("#table_id").data('table');
      let direction = 'desc';
      if ($("#table_id").data('sort-by')) {
        columnIdx = $("#table_id").data('sort-by').split(' ')[0]
        direction = $("#table_id").data('sort-by').split(' ')[1]
      }
      if (!(url))  {
        $("#table_id").dataTable({
          aaSorting: [[columnIdx, direction]],
          paging: ($("#table_id").data('disable-paging') !== true),
          filter: ($("#table_id").data('disable-filter') !== true),
          pageLength: 50,
          columnDefs: [
            { targets: -1, orderable: false }
          ],
          initComplete: () => {
            if (isPage('#admin_results')){
              this.api().columns().each((c) => {
                let column = this;
                if (column.index() !== 5){
                  let select = $('<select><option value=""></option></select>').appendTo($(column.footer()).empty()).on('change', () => {
                    let  val = $.fn.dataTable.util.escapeRegex($(this).val());
                    let vas = val ? '^' + val + '$' : '';
                    column.search(vas, true, false).draw();
                    column.data().unique().sort().forEach((d,j) => {
                      select.append ('<option value="' + d + '">' + d + '</option>')
                    })
                  })
                }
              })
            }
          }
        })
      }
    })


    $(label).append(close)

    let popup;

    //To show & hide the age-out popup
    $('#table_id tbody').on('click', 'img', function () {
        if (popup) {
          popup.nextElementSibling.classList.remove('popup-show')
          if (popup === this) {
            popup = null;
          }
        }
        this.nextElementSibling.classList.add('popup-show');
        popup = this
    } );

    //To move the sorting arrows next to the column header text
    let table = $('#table_id').DataTable();

    table.columns().iterator('column', function (ctx, idx) {
      $(table.column(idx).header()).append('<span class="sort-icon"/>');
    });

    let dt = document.querySelector("#table_id");
    dt.style.width = "100%"
    window.addEventListener('resize', changeFunc)
    dt.addEventListener('resize', changeFunc)
  })
}


export default datatable
