import React, { useState, useEffect, useRef } from 'react';
import mixpanel from 'mixpanel-browser';

/**
 * Frontend based inactivity timeout. At some point would be nice to transition to use ActionCable
 * Uses session storage to track total tabs and local storage to track last active timestamp
 * These variables are used to ensure a single inactive tab will not trigger a signout while all tabs being inactive will
 * @param {integer} timeout_length - The length of inactivity in ms after which a user will be signed out
 * @param {integer} popup_length - The length of inactivity in ms after which a user will receive a popup, prompting activity
 * @param {integer} signed_in - Timestamp used to see when the user last signed in.
 *                              Used now to disallow those with multiple roles from signing in if they have not access the portal within the timeout_length
 */

const Timeout = ({ timeout_length, popup_length, signed_in, current_user_id, environment, track_mixpanel_event }) => {
  mixpanel.init(environment == "production" ? "559707e92448f069bd8f8605b325c89f" : "d4df8882fa928387cf64f85b0fade43f");
  // Hack to not have timeout appear for signin page
  if (window.location.pathname === '/sign_in') return null;

  useEffect(() => {
    signOut(signed_in, timeout_length );
  }, [])

  const [timeRemaining, setTimeRemaining] = useState(timeout_length / 1000 );
  const [displayClass, toggleClass] = useState('pop-up');
  const [modalClass, toggleModal] = useState('modal');
  const [timedOut, toggleTimedOut] = useState(false);

  /**
 * Function called on interval. Used to determine if the user should be signed out, have the modal shown, or have the modal removed
 * Sets appopriate time remaining until signout, and showing the modal
 */

  const trackLoggingOutEvent = () => {
    if (track_mixpanel_event) {
      mixpanel.track("hcp_logged_out_inactivity", {
        current_user_id: current_user_id,
        environment: environment
      })
    }
  }

  const checkActivity = () => {
    const lastActive = localStorage.getItem("COGNOA-LAST-ACTIVE");
    const timePast = Date.now() - lastActive;
    if (timePast >= timeout_length) {
      toggleTimedOut(true)
      fetch('/sign_out').then(() => {
        window.location.replace("/sign_in");
      })
    } else if (modalClass === "modal" && timePast >= popup_length) {
      toggleClass('popup-show centered-popup');
      toggleModal('modal modal-show modal-blurred');

      trackLoggingOutEvent()
      removeListeners()
    } else if (modalClass === "modal modal-show modal-blurred" && timePast < popup_length ) {
      toggleClass('pop-up');
      toggleModal('modal');
    }
    setTimeRemaining(Math.floor((timeout_length - timePast) / 1000));
  }

  /**
 * React version of setInterval, see https://overreacted.io/making-setinterval-declarative-with-react-hooks/ for more details
 */
  useInterval(() => {
    checkActivity();
  }, 1000)


  const message = timedOut ? <p>Signing out... </p> : `You will be signed out in ${timeRemaining} seconds`
  const button = timedOut ? null : <button className="session-btn" onClick={() => {
                                    toggleClass('pop-up');
                                    toggleModal('modal');
                                    localStorage.setItem("COGNOA-LAST-ACTIVE", Date.now());
                                    addListeners();
                                    }}
                                    >
                                    I'm still here
                                   </button>
  return (
    <div role="alertdialog"
      aria-label="session timeout pop up"
      aria-describedby="time-remaining-text"
      aria-modal={true}
      className={modalClass}>
      <div className={displayClass}>
        <p id="time-remaining-text" role="timer">
          {message}
        </p>
         {button}
      </div>
    </div>
  )
}

 /**
 * Does not allow the user to access the page if their last sign in timestamp was past the timeout limit
 * If there are already used tabs, it simply adds to the active tab count
 * Tracking the number of tabs is irrelevant and is just a holdover from an older verison that tracked tabs using localStorage
 * Called on the initial render
 */
const signOut = (signed_in, timeout_length) => {
  let tabs = sessionStorage.getItem("COGNOA-ACTIVE-TABS");
  if (tabs === null) {
    if (signed_in > timeout_length) {
      window.stop();
      document.body.innerHTML = "";
      fetch('/sign_out').then(() => {
        window.location.replace("/sign_in");
      })
    } else {
      sessionStorage.setItem("COGNOA-ACTIVE-TABS", 1)
    }
  } else {
    sessionStorage.setItem("COGNOA-ACTIVE-TABS", Number(tabs) + 1)
  }
  window.addEventListener('beforeunload', () => {
    let tabs = sessionStorage.getItem("COGNOA-ACTIVE-TABS");
    sessionStorage.setItem("COGNOA-ACTIVE-TABS", Number(tabs) - 1);
  });
}

 /**
 * Tracks the page loading as the start of the activity timer and adds the activity listeners
 */

window.addEventListener('DOMContentLoaded', () => {
  resetTimer();
  addListeners();
});

const resetTimer = () => {
  localStorage.setItem("COGNOA-LAST-ACTIVE", Date.now());
}

const addListeners = () => {
  document.addEventListener("click", resetTimer);
  document.addEventListener("mousemove", resetTimer);
  document.addEventListener("ontouchstart", resetTimer);
  document.addEventListener("scroll", resetTimer);
}

const removeListeners = () => {
  document.removeEventListener("click", resetTimer);
  document.removeEventListener("mousemove", resetTimer);
  document.removeEventListener("ontouchstart", resetTimer);
  document.removeEventListener("scroll", resetTimer);
}

 /**
 * Function used to handle setInterval, for more see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default Timeout
